import { render, staticRenderFns } from "./KongMonitoringPanel.vue?vue&type=template&id=2b308082&scoped=true"
import script from "./KongMonitoringPanel.vue?vue&type=script&lang=ts"
export * from "./KongMonitoringPanel.vue?vue&type=script&lang=ts"
import style0 from "./KongMonitoringPanel.vue?vue&type=style&index=0&id=2b308082&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b308082",
  null
  
)

export default component.exports
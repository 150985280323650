
import { Component, Vue } from 'vue-property-decorator';
import FullScreenForm from '@/components/FullScreenForm.vue';
import KongLineChart from '@/components/KongLineChart.vue';
import DatePicker from 'vue2-datepicker';
import { chain, groupBy, map, get, debounce, orderBy} from 'lodash';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import 'vue2-datepicker/index.css';
import * as API from '@/api/kong-monitoring.api';
import { SelectOption } from '@/components/form/form.types';
import GskMultiSelect from '@/components/gsk-components/GskMultiSelect.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import { ChartDateFormats } from '@/constants';


@Component({
  components: {
    FullScreenForm,
    KongLineChart,
    DatePicker,
    GskMultiSelect,
  },
})
export default class KongMonitoringPanel extends Vue {
  public dateRangeForLicenses = [this.oneWeekBeforeDate, this.todaysDate];
  public dateRangeForServices = [this.oneWeekBeforeDate, this.todaysDate];
  public dateRangeForConsumers = [this.oneWeekBeforeDate, this.todaysDate];
  public dateRangeForConnections = [this.oneWeekBeforeDate, this.todaysDate];
  public kongLicenseData: any[] = [];
  public kongServicesData: any[] = [];
  public kongConsumerData: any[] = [];
  public kongConnectionData: any[] = [];;

  public selectedBuForkongLicenseData: string[] = [];
  public selectedBuForKongServicesData: string[] = [];
  public selectedBuForKongConsumerData: string[] = [];
  
  public counts: any = {
    totalKongLicenseCounts: '-',
    totalKongServiceCounts: '-',
    totalKongConsumerCounts: '-',
  }

  public defaultDateFormat = ChartDateFormats.DAY;
  public licensesCountLoading = true;
  public servicesCountLoading = true;
  public consumersCountLoading = true;
  public connectionsCountLoading = true;

  public noOfDaysToPercentageChange = 28;
  async mounted() {
    dayjs.extend(advancedFormat);
    dayjs.extend(weekOfYear);
    dayjs.extend(weekYear);
    this.setSelectedBuForAll();
    const licenses =  API.getKongLicenseData({
      fromDate: this.formattedDate(this.dateRangeForLicenses[0]),
      toDate: this.formattedDate(this.dateRangeForLicenses[1]),
      businessUnitIds: this.selectedBuForkongLicenseData.map(Number)
    })
    const services = API.getKongServicesData({
      fromDate: this.formattedDate(this.dateRangeForServices[0]),
      toDate: this.formattedDate(this.dateRangeForServices[1]),
      businessUnitIds: this.selectedBuForkongLicenseData.map(Number)
    })
    const consumers = API.getKongConsumersData({
      fromDate: this.formattedDate(this.dateRangeForConsumers[0]),
      toDate: this.formattedDate(this.dateRangeForConsumers[1]),
      businessUnitIds: this.selectedBuForkongLicenseData.map(Number)
    })
    const connections = API.getKongConnectionData({
      fromDate: this.formattedDate(this.dateRangeForConnections[0]),
      toDate: this.formattedDate(this.dateRangeForConnections[1]),
    })
    const counts = API.getKongHistoryCounts({
      noOfDaysToPercentageChange: this.noOfDaysToPercentageChange,
      dateSince: dayjs(new Date()).format(this.defaultDateFormat)
    })
    const results = await Promise.all([licenses,services, consumers, connections, counts])
    this.licensesCountLoading = false;
    this.servicesCountLoading = false;
    this.consumersCountLoading = false;
    this.connectionsCountLoading = false;
    this.kongLicenseData = get(results[0], 'data',[]);
    this.kongServicesData = get(results[1], 'data',[]);
    this.kongConsumerData = get(results[2], 'data',[]);
    this.kongConnectionData = get(results[3], 'data',[]);
    this.counts = get(results[4], 'data',[]);;
  }

  get buOptions(): SelectOption[] {
    const bu = EnumsModule.businessUnitFormOptions;
    const unAssignedBuOptionFound = bu.find((bUnit) => bUnit.value === '-1')
    if(!unAssignedBuOptionFound) bu.push({label: 'Unknown', value: '-1'})
    return bu;
  }

  get kongLicenseLimitCount() {
    return ProcessEnvsModule.processEnvs.kongLicenseLimitCount
  }

  get todaysDate() {
    return new Date();
  }

  get dateFormat() {
    return this.defaultDateFormat;
  }

  get oneWeekBeforeDate() {
    return new Date(new Date().setDate(new Date().getDate() - 7));
  }

  onLicenseDataChange = debounce(() => this.onKongLicenseDataChange(), 300);
  onServicesDataChange = debounce(() => this.onKongServicesDataChange(), 300);
  onConsumerDataChange = debounce(() => this.onKongConsumerDataChange(), 300);

  getEnvsById(id: number) {
    return EnumsModule.enumsById.environment[id].mnemonic;
  }

  getColorByEnv(env: string) {
    const colorObj: any = {
      DEV: '#a700ff',
      QA: '#ee4445',
      PROD: '#3cd856'
    }
    const defaultColor = '#a700ff'
    return colorObj[env] || defaultColor;
  }

  getFormattedData(data: any, dateRange: any[]) {
    const formatByDateRange = this.getFormatByDateRange(dateRange);
    return  chain(data)
    .groupBy('environmentId')
    .mapValues(envsArr => groupBy(envsArr, env => dayjs(env.createTimestamp).format(formatByDateRange)))
    .map((val: any,key: any) => ({
      label: this.getEnvsById(key),
      borderColor: this.getColorByEnv(this.getEnvsById(key)),
      backgroundColor: this.getColorByEnv(this.getEnvsById(key)),
      data: this.sortByDate(map(val, (dateValue, dateKey) => (this.getDataValues(dateKey, dateValue.length, formatByDateRange))), formatByDateRange)
    }))
    .value();
  }

  getDateOfWeek(w: number, y: number) {
    const d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
    return new Date(y, 0, d);
  }

  dateValByFormat(val: any, dateFormat: string) {
    if(dateFormat === ChartDateFormats.MONTH) {
      return this.formattedDate(val);
    } else if(dateFormat === ChartDateFormats.WEEK) {
      const weekVals: number[] = val.replace('Week ', '').replace(/ /g,'').split(',');
      return this.formattedDate(this.getDateOfWeek(weekVals[0], weekVals[1]));
    } else {
      return val;
    }
  }

  sortByDate(vals: any, dateFormat: string) {
    return orderBy(vals, val => Date.parse(this.dateValByFormat(val.x, dateFormat)), ['asc']);
  }

  getDataValues(xVal: string, yVal: number, dateFormat: string) {
    if(dateFormat === ChartDateFormats.MONTH) {
      const daysInMonth = dayjs(yVal).daysInMonth()
      return {x: xVal, y: Math.ceil((yVal/daysInMonth))}
    } else if(dateFormat === ChartDateFormats.WEEK) {
      return {x: 'Week ' + xVal, y: Math.ceil(yVal/7)}
    } else {
      return {x: xVal, y: yVal};
    }
  }

  getFormatByDateRange(dateRange: any[]) {
    let format = this.defaultDateFormat;
    const diff = dayjs(dateRange[1]).diff(dateRange[0], 'month')
    if(diff > 0 && diff <= 3) {
      format = ChartDateFormats.WEEK;
    } else if(diff > 3) {
      format = ChartDateFormats.MONTH;
    }
    return format;
  }

  formattedDate(date: Date) {
    return dayjs(date).format(this.dateFormat);
  }

  setSelectedBuForAll() {
    const buValues = this.buOptions.map((bu) => bu.value);
    this.selectedBuForkongLicenseData = buValues;
    this.selectedBuForKongServicesData = buValues;
    this.selectedBuForKongConsumerData = buValues;
  }

  async onKongLicenseDataChange() {
    this.licensesCountLoading = true;
    const from = this.dateRangeForLicenses[0];
    const to = this.dateRangeForLicenses[1];
    if(from && to) {
      this.kongLicenseData = (await API.getKongLicenseData({
        fromDate: this.formattedDate(from),
        toDate: this.formattedDate(to),
        businessUnitIds: this.selectedBuForkongLicenseData.map(Number)
      })).data;
    }
    this.licensesCountLoading = false;
  }

  async onKongServicesDataChange() {
    this.servicesCountLoading = true;
    const from = this.dateRangeForLicenses[0];
    const to = this.dateRangeForLicenses[1];
    if(from && to) {
      this.kongServicesData = (await API.getKongServicesData({
        fromDate: this.formattedDate(from),
        toDate: this.formattedDate(to),
        businessUnitIds: this.selectedBuForKongServicesData.map(Number)
      })).data;
    }
    this.servicesCountLoading = false;
  }

  async onKongConsumerDataChange() {
    this.consumersCountLoading = true;
    const from = this.dateRangeForLicenses[0];
    const to = this.dateRangeForLicenses[1];
    if(from && to) {
      this.kongConsumerData =  (await API.getKongConsumersData({
        fromDate: this.formattedDate(from),
        toDate: this.formattedDate(to),
        businessUnitIds: this.selectedBuForKongConsumerData.map(Number)
      })).data;
    }
    this.consumersCountLoading = false;
  }

  async onKongConnectionDataChange() {
    this.connectionsCountLoading = true;
    const from = this.dateRangeForLicenses[0];
    const to = this.dateRangeForLicenses[1];
    if(from && to) {
      this.kongConnectionData =  (await API.getKongConnectionData({
        fromDate: this.formattedDate(from),
        toDate: this.formattedDate(to),
      })).data;
    }
    this.connectionsCountLoading = false;
  }

  disabledRange(date: any) {
    const yearBeforeDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    return date < yearBeforeDate || date > new Date();
  }
}

import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';

export function getKongLicenseData(data: any): AxiosPromise<any> {
    return axios.post(URLs.KongMonitoringLicenses, data, {});
}

export function getKongServicesData(data: any): AxiosPromise<any> {
    return axios.post(URLs.KongMonitoringServices, data, {});
}

export function getKongConsumersData(data: any): AxiosPromise<any> {
    return axios.post(URLs.KongMonitoringConsumers, data, {});
}

export function getKongConnectionData(data: any): AxiosPromise<any> {
    return axios.post(URLs.KongMonitoringConnections, data, {});
}

export function getKongHistoryCounts(data: any): AxiosPromise<any> {
    return axios.post(URLs.KongHistoryCounts, data, {});
}

import { Component, Vue, Prop } from 'vue-property-decorator';
import { Line as LineChartGenerator } from 'vue-chartjs';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

import annotationPlugin from 'chartjs-plugin-annotation';

const noDataConfig = {
  id: 'NoData',
  afterDraw: function (chart: { data: { datasets: any[]; }; ctx: any; width: any; height: any; clear: () => void; }) {
    // you can use the previous examples "if" here instead if you want.
    // This not only checks the dataset lenght but also the data in each set.
    // If there is no data across all datasets, the message will display
    if (
      chart.data.datasets
        .map((d: { data: string|any[]; }) => d.data.length)
        .reduce((p: any, a: any) => p + a, 0) === 0
    ) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      chart.clear();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      // you can specify a font if you want instead, this gets the font family from the body tag
      ctx.font = `0.8rem ${window.getComputedStyle(document.body).fontFamily}`;
      // you can omit this or use your own logic to adjust the font color.
      // `prefersDark` is a custom property I have
      ctx.fillStyle = 'black';
      ctx.fillText('No data', width / 2, height / 2);
      ctx.restore();
    }
  },
}

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  annotationPlugin,
  noDataConfig
)


@Component({
  components: {
    LineChartGenerator
  },
})
export default class KongLineChart extends Vue {
  @Prop({ type: String, default: 'line-chart' }) chartId!: string;
  @Prop({ type: String, default: 'label' }) datasetIdKey!: string;
  @Prop({ type: Number, default: 400 }) width!: number;
  @Prop({ type: Number, default: 400 }) height!: number;
  @Prop({ type: Array, default: [] }) dataSett!: any[];
  @Prop({ type: Boolean, default: false }) thresholdRange!: boolean;

  get chartData() {
    return {
      datasets: this.dataSett,
      fill: true,
      tension: 0.1
    }
  }
  get chartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom'
        },
        annotation: this.thresholdRange ? {
          annotations: {
            line1: {
              type: 'line',
              yMin: 60,
              yMax: 60,
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 4,
            },
            box1: {
              type: 'box',
              yMin: 0,
              yMax: 60,
              borderWidth: 0,
              backgroundColor: 'rgba(255, 99, 132, 0.25)'
            }
          }
        } : {}
      }
    }
  }
}
